// src/pages/CustomSearch.js

import React, { useEffect, useState } from 'react';
import {
    Select,
    DatePicker,
    TimePicker,
    Button,
    Form,
    Alert,
    Spin,
    Table,
    message,
    Row,
    Col,
    Checkbox,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useStoreInContext } from 'store/main';
import dayjs from 'dayjs';
import Header from 'components/Header';
import './styles.scss';
import { fetchDeviceDataAggregated } from '../../api/dataService';
import { listMachinesByLocation } from '../../api/smaService';
import { formatCurrency } from 'utils/formatters';

const { Option } = Select;

const CustomSearch = () => {
    const { locations } = useStoreInContext((state) => state);
    const [form] = Form.useForm();
    const [machines, setMachines] = useState([]);
    const [loading, setLoading] = useState(false);
    const [aggregatedData, setAggregatedData] = useState([]);
    const [error, setError] = useState(null);
    const [hasSearched, setHasSearched] = useState(false);

    // Estados para os totais
    const [totalCashIn, setTotalCashIn] = useState(0);
    const [totalCashOut, setTotalCashOut] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);

    // Função para buscar máquinas com base nas localizações selecionadas
    const fetchMachines = async (locationIds) => {
        try {
            if (locationIds.length === 0) {
                setMachines([]);
                return;
            }
            const response = await listMachinesByLocation(locationIds);
            setMachines(response.data);
        } catch (err) {
            console.error('Erro ao buscar máquinas:', err);
            message.error('Erro ao buscar máquinas.');
        }
    };

    // Handler quando as localizações mudam
    const handleLocationChange = (locationIds) => {
        fetchMachines(locationIds);
        // Resetar o campo de máquinas quando as localizações mudam
        form.setFieldsValue({ machines: [] });
    };

    // Handler para submissão do formulário
    const onFinish = async (values) => {
        const {
            initialDate,
            initialTime,
            finalDate,
            finalTime,
            locations,
            machines,
            dailyBookKeeping,
            considerRemote,
        } = values;

        if (!initialDate || !finalDate) {
            setError('Por favor, selecione as datas inicial e final.');
            return;
        }

        // Extrair data e hora como strings
        const initialDateStr = initialDate.format('YYYY-MM-DD');
        const initialTimeStr = initialTime ? initialTime.format('HH:mm:ss') : '00:00:00';
        const finalDateStr = finalDate.format('YYYY-MM-DD');
        const finalTimeStr = finalTime ? finalTime.format('HH:mm:ss') : '23:59:59';

        // Combinar data e hora em strings completas
        const startDateTimeStr = `${initialDateStr}T${initialTimeStr}`;
        const endDateTimeStr = `${finalDateStr}T${finalTimeStr}`;

        // Converter strings para objetos dayjs
        const startDateTime = dayjs(startDateTimeStr, 'YYYY-MM-DDTHH:mm:ss', true);
        const endDateTime = dayjs(endDateTimeStr, 'YYYY-MM-DDTHH:mm:ss', true);

        // Validar datas
        if (!startDateTime.isValid() || !endDateTime.isValid()) {
            setError('Formato de data ou hora inválido.');
            return;
        }

        if (startDateTime.isAfter(endDateTime)) {
            setError('A data e hora inicial devem ser antes da data e hora final.');
            return;
        }

        setError(null);
        setLoading(true);
        setHasSearched(true);

        const filters = {
            start_date: startDateTimeStr,
            end_date: endDateTimeStr,
            location_ids: locations,
            nodes: machines || [],
            daily_book_keeping: dailyBookKeeping,
            consider_remote: considerRemote,
        };

        console.log('Data inicial', startDateTimeStr);
        console.log('Data final', endDateTimeStr);

        try {
            const response = await fetchDeviceDataAggregated(filters);
            // Ordenar os dados por sending_node
            const sortedData = response.sort((a, b) => {
                if (a.sending_node < b.sending_node) return -1;
                if (a.sending_node > b.sending_node) return 1;
                return 0;
            });
            setAggregatedData(sortedData);

            // Calcular os totais
            const calculatedTotalCashIn = sortedData.reduce(
                (sum, item) => sum + parseFloat(item.total_coin_in || 0),
                0
            );
            const calculatedTotalCashOut = sortedData.reduce(
                (sum, item) => sum + parseFloat(item.total_cashout || 0),
                0
            );
            const calculatedTotalProfit = calculatedTotalCashIn - calculatedTotalCashOut;

            setTotalCashIn(calculatedTotalCashIn);
            setTotalCashOut(calculatedTotalCashOut);
            setTotalProfit(calculatedTotalProfit);

            if (sortedData.length === 0) {
                message.info('Nenhum dado encontrado para os filtros selecionados.');
            }
        } catch (err) {
            console.error('Erro ao buscar dados agregados:', err);
            setError('Erro ao buscar dados agregados.');
        } finally {
            setLoading(false);
        }
    };

    // Definir colunas para a tabela de resultados
    const columns = [
        {
            title: 'Máquina',
            dataIndex: 'machine_name',
            key: 'machine_name',
            width: 110,
            align: 'left',
            ellipsis: true,
            render: (text, record) => {
                const node = record.sending_node;
                return `${node} - ${text}`;
            },
        },
        {
            title: 'Entrada',
            dataIndex: 'total_coin_in',
            key: 'total_coin_in',
            width: 60,
            align: 'center',
            render: (text) => formatCurrency(parseFloat(text)),
        },
        {
            title: 'Saída',
            dataIndex: 'total_cashout',
            key: 'total_cashout',
            width: 60,
            align: 'center',
            render: (text) => `-${formatCurrency(parseFloat(text))}`,
        },
        {
            title: 'Lucro',
            dataIndex: 'profit',
            key: 'profit',
            width: 60,
            align: 'center',
            render: (text) => {
                const profit = parseFloat(text);
                const profitClass = profit >= 0 ? 'profit-positive' : 'profit-negative';
                return <span className={profitClass}>{formatCurrency(profit)}</span>;
            },
        },
    ];

    // Definir colunas para a tabela de totais
    const totalColumns = [
        {
            title: 'Entrada',
            dataIndex: 'totalCashIn',
            key: 'totalCashIn',
            width: 200,
            align: 'left',
            render: (text) => <strong>{formatCurrency(text)}</strong>,
        },
        {
            title: 'Saída',
            dataIndex: 'totalCashOut',
            key: 'totalCashOut',
            width: 200,
            align: 'left',
            render: (text) => <strong>-{formatCurrency(text)}</strong>,
        },
        {
            title: 'Lucro',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            width: 200,
            align: 'left',
            render: (text) => {
                const profit = parseFloat(text);
                const profitClass = profit >= 0 ? 'profit-positive' : 'profit-negative';
                return (
                    <strong className={profitClass}>{formatCurrency(profit)}</strong>
                );
            },
        },
    ];

    // Função para desabilitar datas
    const disabledDate = (current) => {
        const startLimit = dayjs().subtract(2, 'years');
        const endLimit = dayjs().add(2, 'years');
        return current && (current < startLimit || current > endLimit);
    };

    return (
        <div className="CustomSearch">
            <Header title="Busca" />
            <div className="custom-search-container">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        initialDate: dayjs().subtract(7, 'days'),
                        initialTime: dayjs(),
                        finalDate: dayjs(),
                        finalTime: dayjs(),
                        dailyBookKeeping: false, // Por padrão desmarcado
                        considerRemote: true, // Este valor será usado quando o campo aparecer
                    }}
                >
                    {/* Seleção de Localizações */}
                    <Form.Item
                        label="Localizações"
                        name="locations"
                        rules={[
                            { required: true, message: 'Selecione ao menos uma localização.' },
                        ]}
                    >
                        <Select
                            placeholder="Selecione a(s) localização(ões)"
                            onChange={handleLocationChange}
                            allowClear
                            showSearch={false}
                        >
                            {locations.map((location) => (
                                <Option key={location.id} value={location.id}>
                                    {location.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* Seleção de Máquinas (Opcional) */}
                    <Form.Item
                        label="Máquinas"
                        name="machines"
                        dependencies={['locations']}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Selecione as máquinas (opcional)"
                            allowClear
                            disabled={machines.length === 0}
                            showSearch={false}
                        >
                            {machines.map((machine) => (
                                <Option key={machine.id} value={machine.node}>
                                    {machine.name} (Node: {machine.node})
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* Seleção de Datas e Horas */}
                    <Row gutter={16}>
                        {/* Data Inicial */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Data Inicial"
                                name="initialDate"
                                rules={[
                                    { required: true, message: 'Selecione a data inicial.' },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={disabledDate}
                                    placeholder="Selecione a data inicial"
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>

                        {/* Hora Inicial */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Hora Inicial"
                                name="initialTime"
                                rules={[
                                    { required: true, message: 'Selecione a hora inicial.' },
                                ]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    placeholder="Selecione a hora inicial"
                                    format="HH:mm:ss"
                                    size="small"
                                    showNow={false}
                                />
                            </Form.Item>
                        </Col>

                        {/* Data Final */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Data Final"
                                name="finalDate"
                                rules={[
                                    { required: true, message: 'Selecione a data final.' },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={disabledDate}
                                    placeholder="Selecione a data final"
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>

                        {/* Hora Final */}
                        <Col xs={24} sm={6}>
                            <Form.Item
                                label="Hora Final"
                                name="finalTime"
                                rules={[
                                    { required: true, message: 'Selecione a hora final.' },
                                ]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    placeholder="Selecione a hora final"
                                    format="HH:mm:ss"
                                    size="small"
                                    showNow={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Checkbox Daily Book Keeping */}
                    <Form.Item
                        name="dailyBookKeeping"
                        valuePropName="checked"
                        style={{ marginBottom: '10px' }}
                    >
                        <Checkbox>Daily Book Keeping</Checkbox>
                    </Form.Item>

                    {/* Exibição condicional do checkbox Considerar Remoto */}
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.dailyBookKeeping !== currentValues.dailyBookKeeping
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('dailyBookKeeping') ? (
                                <Form.Item
                                    name="considerRemote"
                                    valuePropName="checked"
                                    style={{ marginBottom: '10px' }}
                                    initialValue={true} // Por padrão marcado
                                >
                                    <Checkbox>Consider remote</Checkbox>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    {/* Exibição de Erro */}
                    {error && (
                        <Form.Item>
                            <Alert message={error} type="error" showIcon />
                        </Form.Item>
                    )}

                    {/* Botão de Submissão */}
                    <Form.Item className="form-submit-button">
                        <Button type="primary" htmlType="submit">
                            Buscar <SearchOutlined className="search-icon" />
                        </Button>
                    </Form.Item>
                </Form>

                {/* Indicador de Carregamento */}
                {loading && <Spin tip="Buscando dados..." />}

                {/* Resultados da Busca */}
                {!loading && aggregatedData.length > 0 && (
                    <div className="results-section">
                        <h2>Resultados</h2>
                        <Table
                            className="custom-table"
                            dataSource={aggregatedData.map((item, index) => ({
                                key: index,
                                ...item,
                                profit:
                                    parseFloat(item.total_coin_in) -
                                    parseFloat(item.total_cashout),
                            }))}
                            columns={columns}
                            pagination={{
                                pageSize: 10,
                                position: ['bottomCenter'],
                            }}
                        />
                        {/* Tabela de Totais */}
                        <div className="totals-section" style={{ marginTop: '20px' }}>
                            <h3>Totais</h3>
                            <Table
                                className="totals-table"
                                dataSource={[
                                    {
                                        key: 'totals',
                                        totalCashIn: totalCashIn,
                                        totalCashOut: totalCashOut,
                                        totalProfit: totalProfit,
                                    },
                                ]}
                                columns={totalColumns}
                                pagination={false}
                                bordered
                            />
                        </div>
                    </div>
                )}

                {/* Mensagem de Nenhum Dado */}
                {!loading && hasSearched && aggregatedData.length === 0 && (
                    <div className="no-data">
                        <Alert
                            message="Nenhum dado encontrado para os filtros selecionados."
                            type="info"
                            showIcon
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSearch;
